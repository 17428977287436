/*Обнуление*/
* {
  padding: 0;
  margin: 0;
  border: 0;
}

*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus, :active {
  outline: none;
}

a:focus, a:active {
  outline: none;
}

nav, footer, header, aside {
  display: block;
}

html, body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 16px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: "Proxima Nova";
}

input, button, textarea {
  font-family: "Proxima Nova";
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a, a:visited {
  text-decoration: none;
  //color: #FFFFFF
}

a:hover {
  text-decoration: none;
  transition: color .6s;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: 400;
}

/*--------------------*/


@font-face {
  font-family: "Proxima Nova";
  src: url("../../../public/fonts/ProximaNova/ProximaNova-Light.eot");
  src: url("../../../public/fonts/ProximaNova/ProximaNova-Light.eot?#iefix") format("embedded-opentype"),
  url("../../../public/fonts/ProximaNova/ProximaNova-Light.woff") format("woff"),
  url("../../../public/fonts/ProximaNova/ProximaNova-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("../../../public/fonts/ProximaNova/ProximaNova-Regular.eot");
  src: url("../../../public/fonts/ProximaNova/ProximaNova-Regular.eot?#iefix") format("embedded-opentype"),
  url("../../../public/fonts/ProximaNova/ProximaNova-Regular.woff") format("woff"),
  url("../../../public/fonts/ProximaNova/ProximaNova-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("../../../public/fonts/ProximaNova/ProximaNova-Semibold.eot");
  src: url("../../../public/fonts/ProximaNova/ProximaNova-Semibold.eot?#iefix") format("embedded-opentype"),
  url("../../../public/fonts/ProximaNova/ProximaNova-Semibold.woff") format("woff"),
  url("../../../public/fonts/ProximaNova/ProximaNova-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

/*@font-face {*/
/*    font-family: "Proxima Nova";*/
/*    src: url("/fonts/ProximaNova/ProximaNova-Bold.eot");*/
/*    src: url("/fonts/ProximaNova/ProximaNova-Bold.eot?#iefix") format("embedded-opentype"),*/
/*    url("/fonts/ProximaNova/ProximaNova-Bold.woff") format("woff"),*/
/*    url("/fonts/ProximaNova/ProximaNova-Bold.ttf") format("truetype");*/
/*    font-weight: 700;*/
/*    font-style: normal;*/
/*}*/

/*@font-face {*/
/*    font-family: "Proxima Nova";*/
/*    src: url("/fonts/ProximaNova/ProximaNova-Black.eot");*/
/*    src: url("/fonts/ProximaNova/ProximaNova-Black.eot?#iefix") format("embedded-opentype"),*/
/*    url("/fonts/ProximaNova/ProximaNova-Black.woff") format("woff"),*/
/*    url("/fonts/ProximaNova/ProximaNova-Black.ttf") format("truetype");*/
/*    font-weight: 900;*/
/*    font-style: normal;*/
/*}*/


$bg-img: url('../../assets/images/BG.png');

.app {
  background-color: #01081F;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main {
  background: $bg-img 0 0/100% auto no-repeat;
  width: 100%;
  height: 1070px;

  @media screen and (max-width: 1150px) {
    height: 900px;
  }
  @media screen and (max-width: 800px) {
    height: 800px;

  }
  @media screen and (max-width: 400px) {
    height: 1000px;
  }
}

