body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Proxima Nova', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}


.wrapper {
  max-width: 1110px;
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 1150px) {
    padding: 0 40px;
  }
  @media screen and (max-width: 800px) {
    padding: 0 30px;
  }
  @media screen and (max-width: 400px) {
    padding: 0 20px;
  }
}

.title {
  font-size: 16px;
  text-transform: uppercase;
  color: #7E8085;
  text-align: center;
  @media screen and (max-width: 800px) {
    font-size: 16px;
  }
  @media screen and (max-width: 400px) {
    font-size: 14px;
    line-height: 20px;
  }
}

.subTitle {
  font-size: 34px;
  color: #FFFFFF;
  line-height: 46px;
  text-align: center;
  @media screen and (max-width: 800px) {
    font-size: 28px;
  }
  @media screen and (max-width: 400px) {
    font-size: 22px;
    line-height: 34px;
  }
}

.text {
  font-size: 16px;
  line-height: 28px;
  color: #7E8085;
  margin-top: 10px;

  & span {
    color: #2F5BEA;
  }
}

.imgWrapper {
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 800px) {
    display: none;
  }
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}